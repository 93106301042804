import axios from "axios"

export const baseURL = process.env.REACT_APP_API_URL
console.log('baseURL', baseURL)

const request = axios.create({
    withCredentials:true,
    baseURL,
    timeout: 5000,
})


export default request